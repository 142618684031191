import React from 'react'
import {useStaticQuery, graphql } from 'gatsby'
import Section from '../components/section/section'
import LayoutSimple from '../components/layout-simple' 

const CookiesPolicyPage = () => {

  const data = useStaticQuery(graphql`
    query CookiesPolicyQuery {
      file(relativePath: {eq: "politica-de-cookies.md"}) {
        childMarkdownRemark {
          html
          frontmatter {
            title
          }
        }
      }
    }
  `)

  const { title } = data.file.childMarkdownRemark.frontmatter;
  const { html } = data.file.childMarkdownRemark;

  return (
    <LayoutSimple>
      <Section title={<h1 className="h2">{title}</h1>}>
        <div style={{paddingBottom: "140px"}} dangerouslySetInnerHTML={{__html: html}}></div>
      </Section>
    </LayoutSimple>
  )
}

export default CookiesPolicyPage